
import { mapState } from 'vuex';

export default {
  name: 'GenericPage',
  head() {
    return this.$utils.head(this.story)
  },
  computed:{
    ...mapState(['location', 'cart']),
    isMenuPage() {
      return this.$route.path === '/menu' || this.$route.path === '/menu/'
    },
    isOrderPage() {
      return this.$route.path === '/order' || this.$route.path === '/order/'
    },
  },
  async asyncData({ $utils, params}) {
    let route = params.pathMatch
    if (route !== '/' && route.endsWith('/')) {
      route = route.replace(/\/+$/, '') || '/'
    }

    return {
      story: await $utils.content(route || 'home')
    }
  },
  beforeMount() {
    if (this.isMenuPage || this.isOrderPage) {
      let path = '';
      if(this.cart && this.cart.menu_id) {
        path = `/menu/${this.cart.menu_id}`
      }
      else if(this.location && this.location.menu_id) {
        path = `/menu/${this.location.menu_id}`
      }
      else {
        path = '/locations'
      }
      this.$nuxt.$router.push({
        path,
        query: this.$route.query
      })
    }
    // must be at the end
    if(!this.isMenuPage && !this.story) {
      this.$nuxt.$router.push('/404');
    }
  }
}
